/* eslint-disable */

import { cmsConstants } from 'Store/Constant/Constants'
const initail_state = {
    loader: false,
    components: [],
    createComponent: [],
    updateComponent: [],
    pages: [],
    createPages: [],
    updatePages: [],
    languages: [],
    createLanguages: [],
    updateLanguages: [],
    comLoader: false,
    data:[]
}

export const CMSReducer = (state = initail_state, action) => {

    switch (action.type) {
        case cmsConstants.CMS_LOADER:
            return { ...state, loader: action.payload }
        case cmsConstants.CHANGE_DATA:
            return { ...state, data: action.payload }
        case cmsConstants.CMS_LOADER_COM:
            return { ...state, comLoader: action.payload }

        case cmsConstants.READ_COMPONENT:
            return { ...state, components: action.payload }
        case cmsConstants.CREATE_COMPONENT:
            return { ...state, createComponent: action.payload }
        case cmsConstants.UPDATE_COMPONENT:
            return { ...state, updateComponent: action.payload }
        case cmsConstants.READ_PAGE:
            return { ...state, pages: action.payload }
        case cmsConstants.CREATE_PAGE:
            return { ...state, createPages: action.payload }
        case cmsConstants.UPDATE_PAGE:
            return { ...state, updatePages: action.payload }
        case cmsConstants.READ_LANG:
            return { ...state, languages: action.payload }
        case cmsConstants.CREATE_LANG:
            return { ...state, createLanguages: action.payload }
        case cmsConstants.UPDATE_LANG:
            return { ...state, updateLanguages: action.payload }
        default:
            return state;
    }

}