/* eslint-disable */
import {
  READ_PACKAGE_CONSTANT,
  CREATE_PACKAGE_CONSTANT,
  UPDATE_PACKAGE_CONSTANT,
} from 'Store/Constant/Constants';

const initail_state = {
  packages: [],
  loading: false,
};

export const ViewPackagesRedcuer = (state = initail_state, action) => {
  switch (action.type) {
    case READ_PACKAGE_CONSTANT.READ_PACKAGE_LOADING:
      return { ...state, loading: action.payload };
    case READ_PACKAGE_CONSTANT.READ_PACKAGE_SUCCESS:
      return { ...state, packages: action.payload };
    case READ_PACKAGE_CONSTANT.READ_PACKAGE_ERROR:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
};
